<template>
    <LiefengContent :isBack="$route.query.isLineHome" @backClick="$router.push('/residentdatabase')">
        <template v-slot:title>
            社区动员体系
            <!-- <Button  type="error" v-if="$route.query.isLineHome" @click="$router.push('/linehomepage')">返回</Button> -->
        </template>
        <template v-slot:toolsbarRight>
            <Form :label-colon="true" :inline="true">
                <FormItem label="切换社区" :label-width="100">
                    <LiefengCascaderCity ref="cascaderCity" :orgCode="4401" :resetNum="0" @changeCasader="changeCasder" :value="orgCodeValue" :isShow="!!orgCodeValue"></LiefengCascaderCity>
                </FormItem>
            </Form>
        </template>
        <template v-slot:contentArea>
            <div class="container">
                <div class="container_left">
                    <div class="container_left_title">
                        <div :class="'container_left_title_box' + (item.finishStatus == '1' ? ' finish_box' : item.finishStatus == '2' ? ' dono_status' : ' ongoing_status')" v-for="(item, index) in leftTitleArr" :key="index">
                            <div class="box_status">{{ item.finishStatus | filterStatus }}</div>
                            <div class="box_title">{{ item.status | filterGroupType(that) }}:</div>
                            <div>{{ item.desc }}</div>
                            <div class="box_btn" v-if="item.number && item.number > 0" @click.stop="openGroup(item)">共 {{ item.number }}个</div>
                            <div class="box_btn cursor_btn" v-else @click.stop="createWorkGroup(index)">创建工作群</div>
                        </div>
                    </div>
                    <div class="container_left_table">
                        <div class="container_left_table_title">
                            <div class="container_left_table_title_name">各组织类型群</div>
                            <div class="container_left_table_title_num">
                                (共
                                <span>{{ groupNumber }}</span>
                                个群)
                            </div>
                        </div>
                        <div class="auto_table_height" ref="auto_table_height">
                            <template v-if="tableData.length > 0">
                                <div class="container_left_table_row" v-for="(item, index) in tableData" :key="index">
                                    <div class="group_type">{{ item.groupType | filterGroupType(that) }}</div>
                                    <div class="group_flex" ref="group_flex">
                                        <div class="group_flex_box" v-for="(subItem, subIndex) in item.children" :key="subIndex">
                                            <div class="group_name">{{ subItem.groupName }}</div>
                                            <div class="group_msg">
                                                <div>
                                                    未读消息数
                                                    <span class="group_msg_num">{{ subItem.number || 0 }}</span>
                                                </div>
                                                <div class="group_msg_btn" @click="seeDetail(subItem)">进入</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </template>
                            <div v-else class="no_data">暂无数据</div>
                        </div>
                    </div>
                </div>
                <div class="container_right">
                    <div class="container_right_title">组织动员运行架构搭建流程</div>
                    <Tooltip content="点击进入" placement="left" theme="light" v-for="(item, index) in processList" :key="index">
                        <div class="container_right_process" @click="navigateToPage(item.action)">
                            <div :class="'container_right_process_desc' + ' desc' + item.status" v-if="item.status !== ''">
                                {{ item.status == "1" ? "已完成" : item.status == "2" ? "未开始" : item.status == "3" ? "进行中" : "" }}
                            </div>
                            <div>{{ index + 1 }}.{{ item.title }}</div>
                            <Icon type="md-arrow-round-down" v-if="index != processList.length - 1" class="next_icon" />
                        </div>
                    </Tooltip>
                </div>
            </div>
            <!-- 发言管理 -->
            <LiefengModal title="消息详情" :fullscreen="true" :value="msgManageModal" @input="msgManageModalFn">
                <template v-slot:contentarea>
                    <MsgManage :msgManageData="msgManageData" @replyMessageSuccess="getTableData" v-if="msgManageModal"></MsgManage>
                </template>
            </LiefengModal>
        </template>
    </LiefengContent>
</template>

<script>
//@route("/mobilizationsystem")
import LiefengContent from "@/components/LiefengContent3"
import MsgManage from "./childrens/msgmanage"
import LiefengModal from "@/components/LiefengModal"
import LiefengCascaderCity from "@/components/LiefengCascaderCity"

export default {
    components: {
        LiefengContent,
        MsgManage,
        LiefengModal,
        LiefengCascaderCity,
    },
    data() {
        return {
            that: this,
            communityCode: [],
            communityList: [],
            memberFromScope: "",
            memberFromName: "",
            leftTitleArr: [
                { status: 15, finishStatus: "", desc: "镇（街）党工委、办事处挂点负责人、村（社区）党委负责人、村（居）负责人、网格党支部负责人、网格员", number: 0 },
                { status: 16, finishStatus: "", desc: "村（社区）“两委” 成员挂点同志、、村（居）负责人、网格党支部负责人、网格员、楼栋党小组长（楼长）", number: 0 },
                { status: 17, finishStatus: "", desc: "楼栋党小组长（楼长）、村（居）民户代表、出租屋主、租户户代表", number: 0 },
            ],
            tableData: [],
            groupNumber: 0,
            processList: [
                { title: "导入社区架构", status: "", action: "/synchronization?isback=true" },
                { title: "划分楼组，创建楼组群（第三工作群）", status: "", action: "/leaderindex?isback=true" },
                { title: "划分网格，创建网格管理群（第二工作群）", status: "", action: "/gridindex?isback=true" },
                { title: "导入社区组织动员成员，设置负责区域", status: "", action: "/mobilizationindex?isback=true" },
                { title: "获取工作群二维码，邀请各代表加入", status: "", action: "/groupindex?isback=true" },
            ],
            currentSelectOrg: 1,
            //消息详情
            msgManageData: null,
            msgManageModal: false,
            loading: false,
            // 初始化默认社区
            orgCodeValue: sessionStorage.getItem("RESIDENT_DATABASE_ORGCODE") || null,
        }
    },
    filters: {
        filterStatus(val) {
            switch (val) {
                case "1":
                    return "已完成"
                case "2":
                    return "未开始"
                case "3":
                    return "进行中"
            }
        },
        filterGroupType(val, that) {
            for (let k in that.groupList) {
                if (that.groupList[k].dictKey == val) {
                    return that.groupList[k].dictValue
                }
            }
        },
    },
    methods: {
        // 跳转到群列表
        openGroup(item) {
            console.log(item)
            this.$router.push(`/grouplist?groupType=${item.status}&isback=true&isLineHome=${this.$route.query.isLineHome}`)
        },
        // 查询建设流程节点
        selectGridConstructionInfo() {
            this.$get("/sendmsg/pc/chat/grid/selectGridConstructionInfo", {
                orgCode: this.memberFromScope,
                orgCodeSelf: parent.vue.loginInfo.userinfo.orgCode,
            }).then(res => {
                if (res.code == 200 && res.data) {
                    this.leftTitleArr[0].finishStatus = res.data.first
                    this.leftTitleArr[1].finishStatus = res.data.second
                    this.leftTitleArr[2].finishStatus = res.data.third
                    this.processList.map((item, index) => {
                        item.status = res.data.flow[index + 1]
                        //if((index + 1) < Number(res.data.flow)) item.status = 1;
                        //else if((index + 1) == Number(res.data.flow)) item.status = 2;
                        //else item.status = 3;
                    })
                    console.log(this.processList)
                }
            })
        },
        /* 创建工作群 */
        createWorkGroup(index) {
            switch (index) {
                case 0:
                    this.$post(
                        "/sendmsg/pc/chat/groupCreate/createGroup",
                        {
                            configRos: [],
                            createMode: "2",
                            creatorId: parent.vue.loginInfo.userinfo.id,
                            groupName: this.memberFromName + "第一工作群",
                            groupType: "15",
                            memberFromScope: this.memberFromScope,
                            orgCode: parent.vue.loginInfo.userinfo.orgCode,
                            oemCode: parent.vue.oemInfo.oemCode,
                            creatorName: parent.vue.loginInfo.userinfo.realName,
                        },
                        { "Content-Type": "application/json" }
                    ).then(res => {
                        if (res.code == 200) {
                            this.$Message.success({
                                content: this.memberFromName + "第一工作群创建成功",
                                background: true,
                            })
                            this.leftTitleArr[0].number = 1
                            this.leftTitleArr[0].finishStatus = "1"
                            this.getTableData([this.communityCode[this.communityCode.length - 1]])
                        } else {
                            this.$Message.error({
                                content: res.desc && res.desc.length < 20 ? res.desc : this.memberFromName + "第一工作群创建失败",
                                background: true,
                            })
                        }
                    })
                    break
                case 1:
                    this.$router.push(`/gridindex?isback=true&isLineHome=${this.$route.query.isLineHome}`)
                    break
                case 2:
                    this.$router.push(`/leaderindex?isback=true&isLineHome=${this.$route.query.isLineHome}`)
                    break
            }
        },
        resize() {
            this.$refs.auto_table_height.style.height = window.innerHeight - this.$(".auto_table_height").offset().top - 20 + "px"
        },
        navigateToPage(action) {
            if (action) this.$router.push(`${action}&isLineHome=${this.$route.query.isLineHome ? this.$route.query.isLineHome : ""}`)
        },
        // 获取区
        getPulishData(code) {
            this.$get("/old/api/pc/staff/selectCascadeDataScope", {
                orgCode: code,
                oemCode: parent.vue.oemInfo.oemCode,
                orgCodeSelf: parent.vue.loginInfo.userinfo.orgCode,
                custGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
            }).then(res => {
                if (res.code == 200 && res.dataList && res.dataList.length > 0) {
                    this.communityList = res.dataList.map(item => {
                        return {
                            value: item.orgPath,
                            label: item.orgName,
                            orgLevel: item.orgLevel,
                            loading: false,
                            children: [],
                        }
                    })
                    this.currentSelectOrg = 2
                    this.communityCode.push(this.communityList[0].value)
                    this.loadCommunity(this.communityList[0])
                }
            })
        },
        // 动态加载街道/社区
        loadCommunity(item, callback) {
            item.loading = true
            let arrCode = item.value.split("-")
            this.$get("/old/api/pc/staff/selectCascadeDataScope", {
                orgCode: arrCode[arrCode.length - 1],
                oemCode: parent.vue.oemInfo.oemCode,
                orgCodeSelf: parent.vue.loginInfo.userinfo.orgCode,
                custGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
            }).then(res => {
                if (res.code == 200) {
                    if (!item.index) {
                        item.children = res.dataList.map(item => {
                            return {
                                value: item.orgPath,
                                label: item.orgName,
                                orgLevel: item.orgLevel,
                                loading: false,
                                children: [],
                                index: 1,
                            }
                        })
                    } else {
                        item.children = res.dataList.map(item => {
                            return {
                                value: item.orgPath,
                                label: item.orgName,
                                orgLevel: item.orgLevel,
                                index: 1,
                            }
                        })
                    }
                    item.loading = false
                    if (callback) callback()
                    else if (item.children[0] && this.currentSelectOrg == 2) {
                        this.currentSelectOrg = 3
                        this.communityCode.push(item.children[0].value)
                        this.loadCommunity(item.children[0])
                    } else {
                        if (item.children && item.children.length > 0) {
                            this.communityCode.push(item.children[0].value)
                            this.memberFromName = item.children[0].label
                            this.getTableData([item.children[0].value])
                        }
                    }
                }
            })
        },
        async getTableData(value, arr) {
            console.log("value", value)
            console.log("memberFromScope", this.memberFromScope)
            this.$Message.loading("正在加载数据...")
            this.tableData = []
            this.loading = true
            if (!value) value = this.communityCode
            if (arr && arr.length > 0) this.memberFromName = arr[arr.length - 1].label
            let orgCode = value[value.length - 1]
            this.memberFromScope = orgCode.split("-")[orgCode.split("-").length - 1] || "" //社区编码
            let params = {
                communityCode: this.memberFromScope,
                custGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
                groupTypes: "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17",
                orgCode: parent.vue.loginInfo.userinfo.orgCode,
            }
            this.getGroupNumber(JSON.parse(JSON.stringify(params)))
            this.selectGridConstructionInfo()
            let res = await this.$get("/sendmsg/pc/chat/groupCreate/selectGroupInfoByType", params)
            this.loading = false
            if (res.code == 200 && res.dataList) {
                this.tableData = res.dataList
                let num = 0
                res.dataList.map(item => {
                    if (item.children) num += item.children.length
                })
                this.groupNumber = num

                this.$Message.destroy()
            } else {
                this.$Message.error({
                    content: res.desc && res.desc.length < 20 ? res.desc : "获取数据失败，请联系管理员处理",
                    background: true,
                })
            }
        },
        getGroupNumber(params) {
            this.leftTitleArr[0].number = 0
            this.leftTitleArr[1].number = 0
            this.leftTitleArr[2].number = 0
            params.groupTypes = "15,16,17"
            this.$get("/sendmsg/pc/chat/groupCreate/selectGroupInfoNumByType", params).then(res => {
                if (res.code == 200 && res.dataList) {
                    res.dataList.map(item => {
                        switch (item.groupType) {
                            case "15":
                                this.leftTitleArr[0].number = item.groupNum
                                break
                            case "16":
                                this.leftTitleArr[1].number = item.groupNum
                                break
                            case "17":
                                this.leftTitleArr[2].number = item.groupNum
                                break
                        }
                    })
                }
            })
        },
        getSelect() {
            this.$get("/dictlist/api/lfcommon/pc/dict/selectDictDataList", {
                dictType: "CHAT_GROUP_TYPE",
                appCode: "",
            }).then(res => {
                this.groupList = res.dataList
            })
        },
        msgManageModalFn(status) {
            this.msgManageModal = status
        },
        seeDetail(data) {
            this.msgManageData = data
            this.msgManageModal = true
        },

        changeCasder(val) {
            if (val?.length === 3) {
                this.communityCode = val
                this.memberFromName = this.getOrgData(this.$refs.cascaderCity.CascaderList, val).orgName
                this.getTableData([val[val.length - 1]])
            }
        },

        /**
         * 根据 orgCode 获取 org 数据
         */
        getOrgData(list, val, idx = 0) {
            let key = val[idx]

            for (let i = 0; i < list.length; i++) {
                let v = list[i]

                if (v.orgCode === key) {
                    if (v.children && v.children.length > 0) {
                        return this.getOrgData(v.children, val, ++idx)
                    }

                    return v
                }
            }

            return {}
        },
    },
    async created() {
        await this.getSelect()
        // 获取广州市的区
        // await this.getPulishData(4401)
    },
    mounted() {
        this.$(window).resize(this.resize)
        this.resize()
    },
}
</script>

<style scoped lang="less">
.ivu-form-item {
    margin-bottom: 0;
}
.container {
    min-height: 100%;
    width: 100%;
    display: flex;
    justify-content: space-between;
    overflow: auto;
    background: #fff;
    &_left {
        flex: 1;
        height: 100%;
        &_title {
            display: flex;
            justify-content: space-between;
            align-items: stretch;
            &_box {
                font-size: 16px;
                border-radius: 6px;
                color: #fff;
                padding: 20px 20px 14px;
                box-sizing: border-box;
                flex: 1;
                position: relative;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                &.finish_box {
                    background: #19be6b;
                    .box_status {
                        border-top: 1px solid #19be6b;
                        border-right: 1px solid #19be6b;
                    }
                }
                &.dono_status {
                    background: #808695;
                    .box_status {
                        border-top: 1px solid #808695;
                        border-right: 1px solid #808695;
                    }
                }
                &.ongoing_status {
                    background: #2d8cf0;
                    .box_status {
                        border-top: 1px solid #2d8cf0;
                        border-right: 1px solid #2d8cf0;
                    }
                }
                &:nth-of-type(2) {
                    margin: 0 16px;
                }
                .box_status {
                    position: absolute;
                    right: 0;
                    top: 0;
                    width: 65px;
                    line-height: 24px;
                    text-align: center;
                    border-radius: 0 6px 0 12px;
                    color: #ed4014;
                    background: #fff;
                }
                .box_title {
                    line-height: 30px;
                    font-size: 18px;
                    font-weight: 500;
                }
                .box_btn {
                    margin: 14px auto 0;
                    height: 32px;
                    line-height: 28px;
                    padding: 0 14px;
                    border-radius: 18px;
                    border: 2px solid #fff;
                    align-self: flex-end;
                    font-weight: bold;
                    cursor: pointer;
                    &.cursor_btn {
                        cursor: pointer;
                    }
                }
            }
        }
        &_table {
            margin-top: 10px;
            .auto_table_height {
                overflow: auto;
                &::-webkit-scrollbar {
                    width: 10px;
                }
                &::-webkit-scrollbar-track {
                    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
                    border-radius: 6px;
                }
                &::-webkit-scrollbar-thumb {
                    border-radius: 6px;
                    background: #2db7f5;
                    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
                }
                .no_data {
                    color: #666;
                    background-color: #f8f8f9;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 100%;
                    font-size: 16px;
                }
            }
            &_title {
                height: 60px;
                text-align: center;
                display: flex;
                align-items: center;
                justify-content: center;
                font-weight: 500;
                background: #f8f8f9;
                border-bottom: 1px solid #e8eaec;
                &_name {
                    font-size: 20px;
                    margin-right: 4px;
                }
                &_num {
                    font-size: 14px;
                    font-size: 16px;
                }
                span {
                    color: #ed4014;
                }
            }
            &_row {
                display: flex;
                align-items: stretch;
                .group_type,
                .group_name {
                    display: flex;
                    align-items: center;
                    max-width: 300px;
                    flex: 1;
                    justify-content: center;
                    line-height: 100%;
                    border: 1px solid #e8eaec;
                }
                .group_type {
                    font-weight: bold;
                }
                .group_flex {
                    flex: 1;
                    .group_flex_box {
                        display: flex;
                        .group_name {
                            min-width: 350px;
                        }
                        .group_msg {
                            padding-left: 20px;
                            flex: 1;
                            height: 30px;
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            border: 1px solid #e8eaec;
                            border-right: none;
                            .group_msg_num {
                                color: #ed4014;
                            }
                            .group_msg_btn {
                                width: 65px;
                                height: 100%;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                color: #fff;
                                background: #2d8cf0;
                                font-weight: 500;
                                cursor: pointer;
                            }
                        }
                    }
                }
            }
        }
    }
    &_right {
        width: 300px;
        min-height: 100%;
        margin: 0 10px;
        display: flex;
        align-items: center;
        flex-direction: column;
        background: #f8f8f9;
        &_title {
            font-size: 22px;
            font-weight: bold;
            margin: 20px 0;
            text-align: center;
        }
        &_process {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 220px;
            min-height: 80px;
            padding: 16px;
            font-weight: bold;
            font-size: 18px;
            border-radius: 8px;
            cursor: pointer;
            border: 1px solid #dcdee2;
            position: relative;
            margin-bottom: 45px;
            box-shadow: 0 0 4px 0 #dcdee2;
            position: relative;
            .next_icon {
                position: absolute;
                bottom: -40px;
                left: 100px;
                transform: translateX(-50%);
                font-size: 36px;
            }
            &_desc {
                position: absolute;
                right: 0;
                top: 0;
                width: 50px;
                line-height: 20px;
                text-align: center;
                border-radius: 0 6px 0 10px;
                color: #fff;
                background: #19be6b;
                font-weight: normal;
                font-size: 14px;
            }
            .desc1 {
                background: #19be6b;
            }
            .desc2 {
                background: #808695;
            }
            .desc3 {
                background: #2d8cf0;
            }
        }
    }
}
</style>
